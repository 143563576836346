import { useContext, useEffect, useState } from 'react';
import { Card, Grid, Box, styled } from '@ltvco/refresh-lib/theme';
import type { ReportRouteProps } from './types';
import { getObituaryNavLinkData } from 'navLinkData/obituaryNavLinkData';
import { useLocation, useNavigate } from 'react-router-dom';
import { constants } from 'appConstants';
import { getClaimedReports } from 'utils/getClaimedReports';
import { formatFamilyTreeDataFromObituaryReport } from 'utils/formatFamilyTreeData';
import {
  DebugMenu,
  DesktopNav,
  FullObituary,
  ObituaryFuneralDetails,
  ObituaryGeneralInformation,
  ObituaryHeader,
  ObituaryLifeEvents,
  ObituaryPersonalInterest,
  ObituaryRelatives,
  ObituaryReport,
  ObituaryReportLoading,
  ObituarySmartInsight,
  PersonSearchCta,
  ReportLoading,
  ReportNavMobile,
  ReportNullState,
  useReport,
  useReportMonitors,
} from '@ltvco/refresh-lib/v1';
import { useAppConstantsContext, AppConfig } from '@ltvco/refresh-lib/ctx';
import {
  useFamilyTreeReports,
  JsonPersonReport,
  JsonObituaryReport,
  AddToFamilyTreeButton,
} from '@ltvco/refresh-lib/fmt';
import { useLLMObituaryParser } from '@ltvco/refresh-lib/hooks';
import {
  isZeroed,
  useScrollToSectionOnNavigate,
} from '@ltvco/refresh-lib/utils';

interface ObituaryReportProps extends ReportRouteProps {}

function parseDate(date: string) {
  if (!date) return false;
  const [month, day, year] = date.split('/').map(Number);
  return {
    full: date,
    parsed: {
      year: year,
      month: month,
      day: day,
    },
  };
}

const StyledContainer = styled(Box)(({ theme }) => ({
  margin: 'auto',
  padding: theme.spacing(2),
  borderRadius: '20px',
  backgroundColor: '#fff',
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    padding: '20px',
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  backgroundRepeat: 'repeat',
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '0',
  [theme.breakpoints.up('sm')]: {
    paddingTop: '80px',
    paddingLeft: '40px',
    paddingRight: '40px',
    paddingBottom: '20px',
  },
}));

const REPORT_TYPE = 'detailed_person_report';

export function ObituaryReportPage({ permalink }: ObituaryReportProps) {
  const {
    data: { brandName },
  } = useAppConstantsContext();
  const queryResult = useReport(permalink);
  const zeroed = isZeroed(queryResult);
  const { hash } = useLocation();
  const { trackEvent } = useContext(AppConfig);
  const report = new ObituaryReport(queryResult?.data);
  const obituary = report.data.obituaries[0];
  const [loading, setLoading] = useState(true);

  const [claimedReportPermalink, setClaimedReportPermalink] =
    useState<string>('');
  const { currentMonitors, reportMonitorIsLoading } = useReportMonitors();
  const { data: reportsData } = useFamilyTreeReports({
    permalink: claimedReportPermalink,
    bodyOptions: {
      report_type: ['ObituaryReport'],
      placeholders_excluded: true,
    },
  });

  const navigate = useNavigate();
  useEffect(() => {
    if (currentMonitors) {
      const claimedReports = getClaimedReports(currentMonitors, REPORT_TYPE);
      if (claimedReports.length > 0) {
        setClaimedReportPermalink(claimedReports[0].permalink);
      }
    }
  }, [currentMonitors]);

  const {
    data: llmResponse,
    error,
    isLoading,
  } = useLLMObituaryParser({
    enabled: Boolean(obituary),
    obituary: obituary,
    customSufixStorageKey: 'obituaryParser',
    shouldSuspendExecution:
      !constants.featureFlags.obituaryParserEnabled || Boolean(!obituary),
  });

  const llmAccomplishments = llmResponse?.data?.accomplishments || [];
  const llmEducation = llmResponse?.data?.educationLevel || [];
  const llmCareer = llmResponse?.data?.jobs || [];
  const llmAge = llmResponse?.data?.age || '';
  const llmParsedNicknames = llmResponse?.data?.nicknames?.join('') || '';
  const llmRelatives = llmResponse?.data?.relatives || [];
  const llmService = llmResponse?.data?.service || null;
  const llmVisitation = llmResponse?.data?.visitation || null;
  const llmDonations = llmResponse?.data?.donations || [];
  const activities = llmResponse?.data?.activities || [];
  const hobbies = llmResponse?.data?.hobbies || [];
  const miscellaneous = llmResponse?.data?.miscellaneous || [];
  const llmParsedDOB = parseDate(llmResponse?.data?.dateOfBirth) || null;
  const llmParsedDOD = parseDate(llmResponse?.data?.dateOfDeath) || null;
  const llmLocation = llmResponse?.data?.location || null;
  const llmPlaceOfBirth = llmResponse?.data?.placeOfBirth || null;

  useEffect(() => {
    if (llmResponse || error) setLoading(false);
  }, [llmResponse, error]);

  useScrollToSectionOnNavigate(hash, queryResult);

  trackEvent('view full report', 'view', 'obituary search');

  if (queryResult.isLoading || queryResult.isError || reportMonitorIsLoading) {
    return <ReportLoading menuItems={2} />;
  }
  if (zeroed) {
    return <ReportNullState />;
  }

  if (!report) return <ReportLoading menuItems={2} />;

  if (!report?.data?.obituaries && !report?.data?.obituaries?.length) {
    return <ReportNullState />;
  }

  const {
    id,
    name,
    dob,
    dod,
    funeralHome,
    funeralHomeAddress,
    funeralHomeUrl,
    message,
    photoUrl,
    nickname,
    personUrl,
    recentCity,
    recentRegion,
  } = obituary;

  document.title = `${name} - ${brandName}`;

  const reportInFamilyTree: boolean | undefined = !claimedReportPermalink
    ? false
    : reportsData?.reports
    ? !!reportsData?.reports?.some((report) => {
        return 'obituary_id' in report && report.obituary_id === id;
      })
    : undefined;

  const locationValue =
    recentCity && recentRegion
      ? `${recentCity}, ${recentRegion}`
      : llmLocation || '';

  const obituaryNavLinkData = getObituaryNavLinkData(llmResponse?.data);

  const handleAddingRelativeToFamilyTree = () => {
    if (!obituary) return null;

    localStorage.setItem(
      'familyTreeRelativeToAdd',
      JSON.stringify(
        formatFamilyTreeDataFromObituaryReport(
          id,
          name,
          dob || llmParsedDOB,
          dod || llmParsedDOD,
          recentCity,
          recentRegion,
          permalink,
          photoUrl
        )
      )
    );
    navigate('/family-tree');
  };

  return (
    <>
      <Grid container direction={'row'} columns={12} spacing={7}>
        <Grid
          item
          sm={12}
          md={4}
          lg={4}
          sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' } }}
        >
          <DesktopNav
            navLinkData={obituaryNavLinkData}
            reportDataPoint={name}
            permalink={permalink}
          />
        </Grid>
        <Grid item sm={12} md={8} lg={8} marginTop={3}>
          <StyledCard
            id="obituary-overview"
            style={{
              backgroundImage: `url(${constants.images.imgTreeOfLife})`,
            }}
          >
            <StyledContainer>
              <ObituaryHeader
                personName={name}
                nickname={nickname || llmParsedNicknames}
                personImage={photoUrl}
                age={llmAge}
                dob={dob || llmParsedDOB}
                dod={dod || llmParsedDOD}
                funeralHome={funeralHome}
                funeralHomeAddress={funeralHomeAddress}
                funeralHomeUrl={funeralHomeUrl}
                photoUrl={photoUrl}
                personUrl={personUrl}
              />
              <AddToFamilyTreeButton
                permalink={permalink}
                reportInFamilyTree={reportInFamilyTree}
                hasFamilyTreeCreated={!!reportsData?.reports}
                addRelativeToFamilyTree={handleAddingRelativeToFamilyTree}
              />
              <ObituaryGeneralInformation
                dob={dob || llmParsedDOB}
                dod={dod || llmParsedDOD}
                placeOfBirth={llmPlaceOfBirth}
                location={locationValue}
                imgInfinity={constants.images.imgInfinity}
                isLoading={isLoading}
              />
            </StyledContainer>
          </StyledCard>
          <FullObituary text={message} personName={name} />
          {isLoading && <ObituaryReportLoading />}
          <ObituarySmartInsight isLoading={isLoading} />
          <ObituaryRelatives relatives={llmRelatives} name={name} />
          <ObituaryLifeEvents
            llmAccomplishments={llmAccomplishments}
            llmEducation={llmEducation}
            llmCareer={llmCareer}
            name={name}
          />
          <ObituaryPersonalInterest
            activies={activities}
            hobbies={hobbies}
            miscellaneous={miscellaneous}
            personName={name}
          />
          <ObituaryFuneralDetails
            service={llmService}
            visitation={llmVisitation}
            donations={llmDonations}
            funeralHomeUrl={funeralHomeUrl}
          />
          <PersonSearchCta
            image={constants.images.imgPersonSearch}
            personName={name}
            location={locationValue}
          />
        </Grid>
      </Grid>
      <ReportNavMobile navLinkData={obituaryNavLinkData} />
      <DebugMenu menuItems={report.data.rawData.debug_menu} />
    </>
  );
}
