import { useEffect, useState, useContext, useMemo } from 'react';
import { Box, Stack } from '@ltvco/refresh-lib/theme';
import {
  type ReportNavigationMenuProps,
  EmailSection as EmailSectionV2,
  PhoneSection as PhoneSectionV2,
  AddressHistorySection as AddressHistorySectionV2,
  NeighborsSection as NeighborsSectionV2,
  RelativesSection as RelativesSectionV2,
  AssociatesSection as AssociatesSectionV2,
  EducationSection as EducationSectionV2,
  JobsSection as JobsSectionV2,
  SocialSection,
  ReportNavigationMenu,
  ReportNavigationMobileMenu,
  PersonReportOverview,
  ReportActionsWithDateUpdated,
  ReportRating as ReportRatingV2,
  NameAncestrySection,
  NotesSection as NotesSectionV2,
  MaritalRecordsSection as MaritalRecordsSectionV2,
  FamilyTreeSection as FamilyTreeSectionV2,
  AssetsSection,
  CriminalOrTrafficSection as CriminalOrTrafficSectionV2,
  BankruptcySection as BankruptcySectionV2,
  CourtRecords,
  CourtRecordsLoadingScreen,
  RelatedReportsSection as RelatedReportsSectionV2,
  LicensesAndPermitsSection as LicensesAndPermitsSectionV2,
  MonitoringBannerSection,
  ReportClaimingSection,
  UsernameSection as UsernameSectionV2,
  SocialSectionV2,
  NavLinkDataV2,
} from '@ltvco/refresh-lib/v2';
import type { ReportRouteProps } from './types';
import {
  getPersonNavLinkData,
  getPersonNavLinkDataV2,
} from 'navLinkData/personNavLinkData';
import { constants } from 'appConstants';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecentReports } from '../../components/BBQ';
import imgIdCard from '../../images/img_idCard.svg';
import imgObituarySearch from '../../images/img_obituary-search.svg';
import { AstrologyBanner } from 'components/Astrology';
import { formatFamilyTreeDataFromPersonReport } from 'utils/formatFamilyTreeData';
import { getClaimedReports } from 'utils/getClaimedReports';
import {
  BurialInformation,
  Connection,
  ContactReportSectionBanner,
  DebugMenu,
  flattenBonusData,
  ObituaryBanner,
  ObituaryDetails,
  Owner,
  PdfSection,
  PersonReport as PersonReportType,
  ReportChangesOverview,
  ReportFactory,
  ReportLoading,
  ReportMonitor,
  ReportNullState,
  ReportOptions,
  RecentReportSerialized,
  useRemouladeReportSnapshot,
  useCreateRemouladeReportSnapshot,
  useReport,
  useReportMonitors,
  useReportRedirect,
  useSession,
  HideableSection,
} from '@ltvco/refresh-lib/v1';
import { AppConfig, HiddenSectionsProvider } from '@ltvco/refresh-lib/ctx';
import { useAncestryTree } from '@ltvco/refresh-lib/fmt';
import {
  isZeroed,
  useScrollToSectionOnNavigate,
  DateUtil,
  nameize,
} from '@ltvco/refresh-lib/utils';
import { useFeatureIsOn, useFeatureValue } from '@ltvco/refresh-lib/vendors';
import { isLessThanOneDayAgo } from 'utils/isLessThanOneDayAgo';
import { useQueryClient } from '@ltvco/refresh-lib/vendors';

interface PersonReportProps extends ReportRouteProps {}

const reportType = 'person';

export function PersonReport({ permalink }: PersonReportProps) {
  const {
    session: { account },
  } = useSession();
  const navigate = useNavigate();
  const { redirectToSearchContactUrl } = useReportRedirect();
  const { trackEvent, trackEventGA4 } = useContext(AppConfig);
  const isInStaggeredInfoVariation = useFeatureIsOn('OAR-1183');
  const { currentMonitors, reportIsMonitored } = useReportMonitors(permalink);
  const coreResult = useReport(permalink);
  const remouladeResult = useRemouladeReportSnapshot(permalink);

  const flattenedRemouladeResult = remouladeResult
    ? { ...remouladeResult, data: remouladeResult.data?.data }
    : remouladeResult;

  const shouldUseRemouladeSnapshot =
    !remouladeResult.isError &&
    (reportIsMonitored || isInStaggeredInfoVariation);
  const queryResult = (
    shouldUseRemouladeSnapshot ? flattenedRemouladeResult : coreResult
  ) as typeof coreResult;
  const isInTrialAttritionVariation = useFeatureIsOn('OAR-1183');

  const zeroed = isZeroed(queryResult);
  const { hash } = useLocation();

  const isInSuggestedEmailVerification = useFeatureIsOn('oar-1146');
  const customTimeDelay = isInSuggestedEmailVerification ? 500 : undefined;
  useScrollToSectionOnNavigate(hash, queryResult, customTimeDelay);

  const isInHideAllCtas = useFeatureIsOn('RFRSH-2722');

  const [showBankruptcyData, setShowBankruptcyData] = useState<boolean>(false);
  const [showCriminalData, setShowCriminalData] = useState(false);
  const [isCourtRecordsLoading, setIsCourtRecordsLoading] = useState(false);

  const [reportOptions, setReportOptions] = useState<ReportOptions>({
    potentialOwnerIndex: 0,
    hideContactInfo: false,
    showHighConfidenceDataOnly: false,
    higherConfidenceThreshold: constants.config.higherConfidenceThreshold,
  });

  const [activeClaiming, setActiveClaiming] = useState(false);
  const [disableClaiming, setDisableClaiming] = useState(false);
  const [claimedReportPermalink, setClaimedReportPermalink] =
    useState<string>('');

  useEffect(() => {
    setDisableClaiming(
      currentMonitors?.some((monitor: ReportMonitor) => {
        return (
          monitor.report_type === 'detailed_person_report' &&
          monitor.is_claimed &&
          permalink !== monitor.permalink
        );
      })
    );
    setActiveClaiming(
      currentMonitors?.some((monitor: ReportMonitor) => {
        return monitor.permalink === permalink && monitor.is_claimed;
      })
    );
  }, [currentMonitors, permalink]);

  const [updateDate, setUpdateDate] = useState<string | undefined>();
  const [report, setReport] = useState<PersonReportType | null>(null);
  const [reportBvId, setReportBvId] = useState<string>('');
  const [filteredRelatives, setFilteredRelatives] = useState<Connection[]>([]);
  const [reportOwner, setReportOwner] = useState<Owner | null>(null);
  const isInUpdatedUsernameAB = useFeatureIsOn('RFRSH-2700');
  const isInPersonReportSummaryVariation = useFeatureIsOn('OAR-1358');
  const date = new DateUtil();

  const createRemouladeReportSnapshot = useCreateRemouladeReportSnapshot();
  const queryClient = useQueryClient();
  queryClient.invalidateQueries(['request', 'bbq']);

  const { data: bbqPersonData } = useRecentReports({
    report_type: 'detailed_person_report',
  });
  const personReports = useMemo(
    () => bbqPersonData?.reports ?? [],
    [bbqPersonData?.reports]
  );

  const currentUserInfo = account?.account?.user_info;
  const shouldHideContactInfo =
    isInStaggeredInfoVariation &&
    isLessThanOneDayAgo(currentUserInfo?.join_date);
  useEffect(() => {
    setReportOptions((prevOptions: ReportOptions) => ({
      ...prevOptions,
      hideContactInfo: shouldHideContactInfo,
    }));
  }, [shouldHideContactInfo]);

  const { data: familyTreeData } = useAncestryTree({
    permalink: claimedReportPermalink,
    enabledOnStart: true,
    logNotFoundErrorEnabled: false,
  });

  useEffect(() => {
    // Only run the request to remoulade if this report is the user's only person report, or "first" report
    if (!isInStaggeredInfoVariation || personReports.length != 1) return;

    createRemouladeReportSnapshot.mutate({
      permalink,
      update_type: 'staggered_info',
    });
  }, [isInStaggeredInfoVariation, personReports]);

  useEffect(() => {
    if (queryResult.isSuccess && queryResult.data) {
      // The format of queryResult from REMO has an extra data key.
      if (queryResult?.data?.entities)
        setReport(ReportFactory.create(queryResult?.data, 'person'));
      else setReport(ReportFactory.create(queryResult?.data.data, 'person'));
    }
  }, [queryResult.data, queryResult.isSuccess, reportOptions]);

  useEffect(() => {
    const remoUpdateDate = currentMonitors?.find(
      (monitor) => monitor.permalink === permalink
    )?.updated_at;
    const coreUpdateDate = report?.data?.meta?.updated_at;

    if (reportIsMonitored && remoUpdateDate)
      setUpdateDate(date.parseDateFromString(remoUpdateDate, 'yyyy-MM-dd'));
    else if (coreUpdateDate)
      setUpdateDate(
        date.parseDateFromString(
          coreUpdateDate,
          'yyyy-MM-dd',
          'yyyy-MM-dd HH:mm:ss ZZZ'
        )
      );
  }, [
    report?.data?.meta?.updated_at,
    currentMonitors,
    permalink,
    reportIsMonitored,
  ]);

  useEffect(() => {
    if (!currentMonitors) return;

    const REPORT_TYPE = 'detailed_person_report';
    const claimedReports = getClaimedReports(currentMonitors, REPORT_TYPE);

    if (claimedReports.length) {
      const mainPermalink = claimedReports[0].permalink;
      setClaimedReportPermalink(mainPermalink);
    } else {
      if (claimedReportPermalink !== '') {
        setClaimedReportPermalink('');
      }
    }
  }, [currentMonitors, claimedReportPermalink]);

  useEffect(() => {
    if (report) {
      const tempReportOwner = report.getOwner(reportOptions);
      if (!!tempReportOwner) {
        setReportOwner(tempReportOwner);
        setFilteredRelatives(tempReportOwner.relatives || []);
      }

      const queryParameters =
        report?.data?.meta?.request_info?.query_parameters;

      if (queryParameters) {
        const bvidObj = queryParameters.find(
          (param: { name: string }) => param.name === 'bvid'
        );
        setReportBvId(bvidObj?.['values']?.[0] || '');
      }
    }
  }, [report, reportOptions]);

  useEffect(() => {
    if (!!familyTreeData?.indis && filteredRelatives.length > 0) {
      const relativesFromFamilyTree = familyTreeData.indis;
      const confirmedRelativesBvid = relativesFromFamilyTree.map(
        (relative) => relative.bvid
      );

      const tempFilteredRelatives = filteredRelatives.filter((relative) => {
        return !confirmedRelativesBvid.includes(relative.bvId);
      });

      // Prevents infinite setting of filtered relatives.
      if (tempFilteredRelatives.length !== filteredRelatives.length) {
        setFilteredRelatives(tempFilteredRelatives);
      }
    }
  }, [familyTreeData, filteredRelatives]);

  const hideCriminalBJLData = account?.account.user_settings
    .hide_criminal_records as boolean;

  if (queryResult.isLoading || queryResult.isError) {
    return <ReportLoading menuItems={14} />;
  }

  if (zeroed) {
    return <ReportNullState />;
  }

  const reportUpgraded = queryResult?.data?.meta?.report_upgraded;
  const revealFreeBonusData = showCriminalData && !isCourtRecordsLoading;

  let showReportClaiming = false;
  if (
    currentUserInfo?.first_name &&
    currentUserInfo.last_name &&
    report?.personName.includes(currentUserInfo.first_name) &&
    report?.personName.includes(currentUserInfo.last_name)
  ) {
    showReportClaiming = true;
  }

  const MonitoringComponent =
    showReportClaiming && !disableClaiming ? (
      <ReportClaimingSection
        reportPermaLink={permalink}
        reportType={reportType}
        reportIsMonitoredAndClaimed={activeClaiming}
      />
    ) : reportIsMonitored ? null : (
      <MonitoringBannerSection
        reportPermaLink={permalink}
        reportType={reportType}
      />
    );

  if (!reportOwner || !report) return <ReportLoading menuItems={14} />;

  if (!report?.data?.people && !report?.data?.people?.length) {
    return <ReportNullState />;
  }

  const {
    ownerName,
    nameInfo,
    emails,
    phones,
    educations,
    jobs,
    profiles,
    usernames,
    addresses,
    ownedAssets,
    marital,
    relatives,
    associates,
    neighbors,
    courts,
    obituary,
    grave,
    bonusData,
  } = reportOwner as Owner;
  const flattenedBonusData = flattenBonusData(bonusData);

  const familyTreeRelatives = familyTreeData?.indis || [];

  const personNavLinkData = getPersonNavLinkData(
    reportOwner as Owner,
    showCriminalData,
    showBankruptcyData,
    hideCriminalBJLData,
    reportUpgraded,
    flattenedBonusData.length,
    relatives || [],
    familyTreeRelatives
  );

  const personNavLinkDataV2 = getPersonNavLinkDataV2(
    reportOwner as Owner,
    hideCriminalBJLData,
    revealFreeBonusData,
    relatives || [],
    familyTreeRelatives
  );

  const dob =
    reportOwner.identity?.dobs?.[0]?.date?.parsed?.year?.toString() ?? '';
  const dod = reportOwner.identity?.dods[0]?.date?.full || '';
  const isDeceased = !!dod;
  const shouldShowObituarySection = !!obituary?.funeralHome;
  const shouldShowObituaryCta = isDeceased && !shouldShowObituarySection;
  const astrologySign = reportOwner.identity?.dobs?.[0]?.zodiac_sign || '';
  const shouldShowAstrologyBanner =
    !!astrologySign && !isDeceased && !isInHideAllCtas;
  const shouldShowPDFBanner = !isInHideAllCtas;
  const shouldShowContactReportBanner = !isInHideAllCtas;
  const shouldShowMonitoringBanner = !isInHideAllCtas;

  document.title = `${report.personName} - BeenVerified`;

  const handleOnBeginSearch = () => {
    trackEvent('person report promo', 'navigation click', 'contact search');
    redirectToSearchContactUrl({});
  };

  const reportInFamilyTree: boolean | undefined = !claimedReportPermalink
    ? // Button is enabled if there is no claimed report
      false
    : familyTreeData?.indis
    ? !!familyTreeData.indis.some((relative) => {
        if (relative.placeholder) return false;
        return relative.reports?.some(
          (report) => 'bvid' in report && report.bvid === reportBvId
        );
      })
    : undefined;

  const handleAddingRelativeToFamilyTree = () => {
    trackEventGA4({
      event_name: 'report_element_engagement',
      report_type: 'people',
      type: 'add_family',
      interacted_from: 'overview',
    });

    const person = report.data.people?.[0];
    if (!person) return null;

    localStorage.setItem(
      'familyTreeRelativeToAdd',
      JSON.stringify(
        formatFamilyTreeDataFromPersonReport(
          person,
          permalink,
          person.identity.ids[0] || '',
          addresses[0]?.parsed || ''
        )
      )
    );

    navigate('/family-tree');
  };

  const handleAddingPossibleRelativeToFamilyTree = (bvid: string) => {
    trackEventGA4({
      event_name: 'report_element_engagement',
      report_type: 'people',
      type: 'add_family',
      interacted_from: 'possible_relatives',
    });
    const relative = relatives?.find(
      (person) => person.identity.ids[0] === bvid
    );
    if (!relative) return null;

    localStorage.setItem(
      'familyTreeRelativeToAdd',
      JSON.stringify(
        formatFamilyTreeDataFromPersonReport(
          relative,
          null,
          bvid,
          relative.addresses[0]?.parsed || ''
        )
      )
    );

    navigate('/family-tree');
  };

  const randomDelayForCourtRecords = Math.random() * 5000;

  function handleCourtRecordsLoadingScreen() {
    setIsCourtRecordsLoading(true);
    setTimeout(() => {
      setIsCourtRecordsLoading(false);
      setShowCriminalData(true);
    }, randomDelayForCourtRecords * 6);
  }
  const redirectUrl = `${window.location}`;
  return (
    <HiddenSectionsProvider reportType="detailed_person_report">
      <Box>
        <Stack direction="row">
          <Box
            sx={{
              display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' },
            }}
          >
            <ReportNavigationMenu
              reportType="Person Report"
              headerTitle={ownerName}
              menuItems={
                personNavLinkDataV2 as ReportNavigationMenuProps['menuItems']
              }
            />
          </Box>

          <Box marginTop={0}>
            <Box
              sx={{
                height: 30,
                backgroundColor: '#f8f8f8',
                position: 'sticky',
                marginBottom: '-10px',
                top: 52,
                zIndex: 10,
                display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' },
              }}
            />

            <ReportActionsWithDateUpdated
              reportType={reportType}
              reportTitle="Person"
              dateUpdated={updateDate}
              permalink={isInStaggeredInfoVariation ? permalink : undefined}
              navLinkData={
                isInStaggeredInfoVariation
                  ? (personNavLinkDataV2 as unknown as NavLinkDataV2[])
                  : []
              }
              reportUpgraded={reportUpgraded}
            />

            <PersonReportOverview
              reportType={reportType}
              owner={reportOwner as Owner}
              personName={ownerName}
              personOverviewItemsData={{
                hideCriminalBJLData,
                phones,
                emails,
                addresses,
                relatives,
                images: reportOwner!.images,
                profiles,
                usernames,
                jobs,
                educations,
              }}
              dateUpdated={updateDate}
              permalink={permalink}
              navLinkData={personNavLinkDataV2 as unknown as NavLinkDataV2[]}
              reportUpgraded={revealFreeBonusData}
              familyTreeCTA={true}
              hasFamilyTreeCreated={!!familyTreeData?.indis?.length}
              reportInFamilyTree={reportInFamilyTree}
              addRelativeToFamilyTree={handleAddingRelativeToFamilyTree}
              isInPersonReportSummaryVariation={
                isInPersonReportSummaryVariation
              }
            />

            {shouldShowMonitoringBanner && MonitoringComponent}

            {shouldShowObituaryCta && (
              <ObituaryBanner image={imgObituarySearch} />
            )}

            {shouldShowAstrologyBanner && (
              <AstrologyBanner
                user={{ name: ownerName, sign: astrologySign }}
                identifier={permalink}
                disableOptions={['lifepath', 'personality', 'ascendant']}
                disableResetCta
              />
            )}

            {shouldShowPDFBanner && <PdfSection permalink={permalink} />}
            <HideableSection
              nameToDisplay="Possible Phone Numbers"
              nameToRecord="possible_phone_numbers"
              id="phone-numbers-section"
            >
              <PhoneSectionV2
                personName={ownerName}
                phoneList={phones}
                permalink={permalink}
              />
            </HideableSection>

            <HideableSection
              nameToDisplay="Possible Email Addresses"
              nameToRecord="possible_email_addresses"
              id="email-section"
            >
              <EmailSectionV2
                personName={ownerName}
                emailList={emails}
                permalink={permalink}
              />
            </HideableSection>

            <HideableSection
              nameToDisplay="Possible Address History"
              nameToRecord="possible_address_history"
              id="address-history-section"
            >
              <AddressHistorySectionV2
                addresses={addresses}
                personName={ownerName}
                permalink={permalink}
              />
            </HideableSection>

            {isInUpdatedUsernameAB ? (
              <>
                <HideableSection
                  nameToDisplay="Possible Social Media"
                  nameToRecord="possible_social_media"
                  id="possible-social-media"
                >
                  <SocialSectionV2
                    personName={ownerName}
                    profiles={profiles}
                    permalink={permalink}
                  />
                </HideableSection>

                <HideableSection
                  nameToDisplay="Possible Usernames"
                  nameToRecord="usernames-section"
                  id="possible-usernames"
                >
                  <UsernameSectionV2
                    personName={ownerName}
                    usernames={usernames}
                    permalink={permalink}
                  />
                </HideableSection>
              </>
            ) : (
              <HideableSection
                nameToDisplay="Possible Social Media"
                nameToRecord="possible_social_media"
                id="possible-social-media"
              >
                <SocialSection
                  personName={ownerName}
                  profiles={profiles}
                  usernames={usernames}
                  permalink={permalink}
                />
              </HideableSection>
            )}

            <HideableSection
              nameToDisplay="Names & Ancestry"
              nameToRecord="names_and_ancestry"
              id="names-ancestry-section"
            >
              <NameAncestrySection
                firstName={nameize(nameInfo?.parsed?.first ?? null)}
                surName={nameize(nameInfo?.parsed?.last ?? null)}
                yearOfBirth={dob}
              />
            </HideableSection>

            <HideableSection
              nameToDisplay="Family Tree"
              nameToRecord="family_tree"
              id="family-tree-section"
            >
              <FamilyTreeSectionV2
                permalink={permalink}
                familyTreeData={familyTreeData}
                personName={ownerName}
              />
            </HideableSection>

            <HideableSection
              nameToDisplay="Possible Relatives"
              nameToRecord="possible_relatives"
              id="relatives-section"
            >
              <RelativesSectionV2
                personName={ownerName}
                relatives={relatives || []}
                permalink={permalink}
                familyTreeData={familyTreeData}
                addRelativeToFamilyTree={
                  handleAddingPossibleRelativeToFamilyTree
                }
              />
            </HideableSection>

            <>
              {shouldShowObituarySection && (
                <ObituaryDetails obituary={obituary} permalink={permalink} />
              )}
              {grave && (
                <BurialInformation grave={grave} permalink={permalink} />
              )}
            </>

            <HideableSection
              nameToDisplay="Possible Marriage & Divorce Records"
              nameToRecord="possible_marriage_divorce_records"
              id="marital-records-section"
            >
              <MaritalRecordsSectionV2
                personName={ownerName}
                marital={marital}
                permalink={permalink}
              />
            </HideableSection>

            <HideableSection
              nameToDisplay="Possible Associates"
              nameToRecord="possible_associates"
              id="associates-section"
            >
              <AssociatesSectionV2
                personName={ownerName}
                associates={associates || []}
                permalink={permalink}
              />
            </HideableSection>

            <HideableSection
              nameToDisplay="Possible Neighbors"
              nameToRecord="possible_neighbors"
              id="neighbors-section"
            >
              <NeighborsSectionV2
                personName={ownerName}
                neighbors={neighbors || []}
                permalink={permalink}
              />
            </HideableSection>

            <HideableSection
              nameToDisplay="Court Orders"
              nameToRecord="court_orders"
              id="criminal-or-traffic-section"
            >
              {!hideCriminalBJLData && (
                <>
                  {revealFreeBonusData ? (
                    <>
                      <CriminalOrTrafficSectionV2
                        criminalOrTrafficData={courts.criminal || []}
                        personName={ownerName}
                      />
                      <BankruptcySectionV2
                        personName={ownerName}
                        bankruptcyData={courts.bankruptcy || []}
                      />
                      <LicensesAndPermitsSectionV2
                        personName={ownerName}
                        bonusData={bonusData}
                      />
                    </>
                  ) : (
                    <>
                      {isCourtRecordsLoading ? (
                        <CourtRecordsLoadingScreen
                          personName={ownerName}
                          delayDuration={randomDelayForCourtRecords}
                        />
                      ) : (
                        <CourtRecords
                          handleCourtRecordsLoadingScreen={
                            handleCourtRecordsLoadingScreen
                          }
                          redirectUrl={redirectUrl}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </HideableSection>

            <HideableSection
              nameToDisplay="Possible Jobs"
              nameToRecord="possible_jobs"
              id="jobs-section"
            >
              <JobsSectionV2
                personName={ownerName}
                jobs={jobs}
                permalink={permalink}
              />
            </HideableSection>

            {shouldShowContactReportBanner && (
              <ContactReportSectionBanner
                image={imgIdCard}
                onBeginSearchClick={handleOnBeginSearch}
              />
            )}

            <HideableSection
              nameToDisplay="Possible Education"
              nameToRecord="possible_education"
              id="education-section"
            >
              <EducationSectionV2
                personName={ownerName}
                educations={educations}
                permalink={permalink}
              />
            </HideableSection>

            <HideableSection
              nameToDisplay="Possible Owned Assets"
              nameToRecord="possible_owned_assets"
              id="possible-owned-assets-section"
            >
              <AssetsSection
                personName={ownerName}
                ownedAssets={ownedAssets}
                permalink={permalink}
              />
            </HideableSection>

            <HideableSection
              nameToDisplay="notes"
              nameToRecord="notes"
              id="Notes-section"
            >
              <NotesSectionV2 permalink={permalink} />
            </HideableSection>

            <HideableSection
              nameToDisplay="Related Reports"
              nameToRecord="related_reports"
              id="related-reports-section"
            >
              <RelatedReportsSectionV2
                reportType="person"
                personName={ownerName}
                potentialOwners={report.data.people}
                relatives={relatives}
                addresses={addresses}
                phones={phones}
                emails={emails}
                usernames={usernames}
              />
            </HideableSection>

            <ReportRatingV2
              rating={report.data.meta?.rating || null}
              report_type={reportType}
            />
          </Box>
        </Stack>

        <ReportNavigationMobileMenu
          reportType="Person Report"
          headerTitle={ownerName}
          menuItems={
            personNavLinkDataV2 as ReportNavigationMenuProps['menuItems']
          }
        />

        <DebugMenu menuItems={report.data.rawData.debug_menu} />
      </Box>
    </HiddenSectionsProvider>
  );
}
